<template>
  <tp-modal max-width="960px" name="modal-category-detail" width="70%">
    <v-card>
      <v-toolbar
        class="px-5 pb-0 align-start"
        extension-height="40px"
        height="56x"
      >
        <v-toolbar-title class="font-weight-bold">
          Thêm loại sản phẩm
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col>
        <v-form ref="productTypeForm" lazy-validation>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col class="py-0" cols="12">
                <div class="font-weight-bold mb-2">Tên loại sản phẩm</div>
                <tp-text-field
                  v-model="productType.name"
                  placeholder="Nhập tên loại sản phẩm"
                  validate="required"
                ></tp-text-field>
              </v-col>

              <v-col class="py-0 category-bar-show" cols="12">
                <v-checkbox
                  v-model="productType.cate_bar_show"
                  :label="`Hiển thị trên Navbar`"
                  class="p-0"
                ></v-checkbox>
              </v-col>

              <v-col class="py-0" cols="12">
                <div class="font-weight-bold mb-2">Mô tả</div>
                <tp-textarea
                  v-model="productType.description"
                  background-color="white"
                  class="text-body-1"
                  counter
                  dense
                  filled
                  maxlength="320"
                  outlined
                  placeholder="Nhập mô tả"
                  single-line
                  validate="required"
                ></tp-textarea>
              </v-col>

              <v-col class="py-0" cols="12">
                <div class="d-flex align-center justify-space-between mb-1">
                  <div class="font-weight-bold">Ảnh bìa</div>
                  Kích thước ảnh yêu cầu là
                  <span class="font-weight-bold">2400 x 640 px</span>
                </div>

                <tp-input-image
                  :acceptedFileTypes="['image/*']"
                  :height="320 * 2"
                  :maxFileSize="500 * 1024"
                  :src.sync="productType.thumbnail"
                  :width="1200 * 2"
                  allowCompress
                  allowFileDimensionValidation
                  allowFileSize
                  allowFileTypeValidation
                  class="flex-grow-1"
                  forceType="jpeg"
                  required
                ></tp-input-image>
              </v-col>

              <v-col class="py-0" cols="12">
                <div class="d-flex align-center justify-space-between mb-1">
                  <div class="font-weight-bold">Ảnh đại diện</div>
                </div>

                <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
                  Kích thước ảnh yêu cầu là
                  <span class="font-weight-bold">512 x 512 px</span>
                </div>

                <tp-input-image
                  :acceptedFileTypes="['image/png']"
                  :height="256 * 2"
                  :maxFileSize="300 * 1024"
                  :src.sync="productType.icon"
                  :width="256 * 2"
                  allowCompress
                  allowFileDimensionValidation
                  allowFileSize
                  allowFileTypeValidation
                  class="flex-grow-1"
                  forceType="png"
                  required
                ></tp-input-image>
              </v-col>

              <v-col class="py-0" cols="12">
                <form-site-model-available
                  v-model="modelSiteConfigs"
                  :model-id="productType.id"
                  :model-type="LIST_MODEL_TYPES.PRODUCT_TYPE"
                />
              </v-col>

              <v-col class="py-0 mt-1 mb-4" cols="12">
                <div class="font-weight-bold mb-2">
                  Loại sản phẩm cha
                </div>
                <treeselect
                  v-model="productType.parent_id"
                  :options="this.categoryTrees"
                  class="custom-treeselect-category"
                  height="100"
                  placeholder="Chọn Category"
                  search-nested
                ></treeselect>
              </v-col>

              <v-col class="py-0 mt-1 mb-4" cols="12">
                <form-seo-data
                  :key="productType.id"
                  v-model="productType.seo_data"
                ></form-seo-data>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions class="px-0 pb-0 d-flex justify-end">
          <v-btn
            class="grey lighten-5 rounded-lg mr-2"
            color="grey--text text--darken-1"
            text
            @click="resetProductType()"
          >
            Hủy
          </v-btn>
          <v-btn
            :loading="
              productTypeStatusRequest.value === 'loading-updateProductType'
            "
            class="cyan lighten-5 rounded-lg mr-2"
            color="primary"
            text
            @click="createProductType()"
          >
            Thêm
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-card>
  </tp-modal>
</template>

<script>
import { toFormData } from "@vt7/utils";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import FormSeoData from "@/core/components/form/FormSeoData";
import FormSiteModelAvailable from "@/core/components/form/FormSiteModelAvailable";
import { LIST_MODEL_SITES, LIST_MODEL_TYPES } from "@/core/constant";

export default {
  components: { Treeselect, FormSiteModelAvailable, FormSeoData },
  data() {
    return {
      formDataCateBarThumbnail: null,
      formDataThumbnail: null,
      LIST_MODEL_SITES,
      LIST_MODEL_TYPES,
      modelSiteConfigs: [],
      thumbnailDevice: "MOBILE"
    };
  },
  computed: {
    priorityList() {
      if (!this.productTypeParent || !this.productTypeParent.count_children) {
        return [1];
      } else {
        const N = this.productType.id
          ? this.productTypeParent.count_children
          : this.productTypeParent.count_children + 1;

        return Array.from(Array(N), (_, index) => index + 1);
      }
    },
    productType() {
      return this.$store.getters["PRODUCT_TYPE/productType"];
    },
    categoryTrees() {
      return this.$store.getters["PRODUCT_TYPE/categoryTrees"];
    },
    productTypeParent() {
      return this.productTypes.find(
        item => item.id === this.productType.parent_id
      );
    },
    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    },
    productTypeStatusRequest() {
      return this.$store.getters["PRODUCT_TYPE/statusRequest"];
    },
    uploadedThumbnail() {
      return this.$store.getters["PRODUCT_TYPE/uploadedThumbnail"];
    },
    seoData() {
      return this.productType.seo_data.reduce(
        (obj, cur) => ({ ...obj, [cur.site]: cur }),
        {}
      );
    }
  },

  watch: {
    "productType.id": {
      async handler(val) {
        if (val) {
          this.getModelSiteConfigs();
        }
      },
      immediate: true
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-category-detail"
      });
    },

    async onUploadThumbnailDesktop() {
      await this.uploadThumbnail(
        toFormData({
          file: this.productType.thumbnail
        })
      );

      // Set uploaded thumbnail
      if (this.productTypeStatusRequest.value === "success-uploadThumbnail") {
        this.productType.thumbnail = this.uploadedThumbnail;
      } else if (
        this.productTypeStatusRequest.value === "error-uploadThumbnail"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh bìa desktop",
          message: this.productTypeStatusRequest.message
        });

        return;
      }
    },

    async onUploadIcon() {
      await this.uploadThumbnail(
        toFormData({
          file: this.productType.icon
        })
      );

      // Set uploaded thumbnail
      if (this.productTypeStatusRequest.value === "success-uploadThumbnail") {
        this.productType.icon = this.uploadedThumbnail;
      } else if (
        this.productTypeStatusRequest.value === "error-uploadThumbnail"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh đại diện",
          message: this.productTypeStatusRequest.message
        });

        return;
      }
    },

    //TODO Cannot save model site config when create new product type
    async createProductType() {
      const isValid = this.$refs.productTypeForm.validate();

      if (!isValid) return;

      // Check thumbnail changes
      if (this.productType.thumbnail instanceof File) {
        await this.onUploadThumbnailDesktop();
      }

      // Check cate bar thumbnail changes
      if (this.productType.icon instanceof File) {
        await this.onUploadIcon();
      }

      await this.$store.dispatch("PRODUCT_TYPE/createProductType", {
        data: this.productType
      });

      // Alert
      if (this.productTypeStatusRequest.value === "success-createProductType") {
        if (!this.modelSiteConfigs.filter(item => item.model_id).length) {
          this.modelSiteConfigs = this.modelSiteConfigs.map(item => ({
            ...item,
            model_id: this.productType.id
          }));
        }

        await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: this.modelSiteConfigs,
          modelId: this.productType.id,
          modelType: LIST_MODEL_TYPES.PRODUCT_TYPE
        });

        await this.getModelSiteConfigs();

        this.closeModal();

        showToastActionAlert(this, { message: "Đã thêm thành công" });
      } else if (
        this.productTypeStatusRequest.value === "error-createProductType"
      ) {
        showModalAlertError(this, {
          title: "Không thể thêm loại sản phẩm",
          message: this.productTypeStatusRequest.message
        });
      }
    },

    async getModelSiteConfigs() {
      this.modelSiteConfigs = await this.$store.dispatch(
        "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
        {
          modelId: this.productType.id,
          modelType: LIST_MODEL_TYPES.PRODUCT_TYPE
        }
      );
    },

    resetProductType() {
      this.$store.dispatch("PRODUCT_TYPE/resetProductType");
      this.$refs.productTypeForm.resetValidation();
      this.modelSiteConfigs = [];
      this.closeModal();
    },

    updateProductSite() {
      this.productType.sites = [];
      this.modelSiteConfigs.map(item => {
        if (item.available) {
          this.productType.sites.push(item.site);
          return item.site;
        }
      });
    },

    async uploadThumbnail(formData) {
      if (!formData) return;

      await this.$store.dispatch("PRODUCT_TYPE/uploadThumbnail", formData);
    }
  }
};
</script>

<style lang="scss">
.custom-treeselect-category .vue-treeselect__value-container {
  font-size: 14px;
}
</style>
