<template>
  <div class="product-category h-full">
    <base-tree
      v-if="
        productTypeStatusRequest.value !== 'loading-sortProductTypes' &&
          productTypeStatusRequest.value !== 'loading-getAllProductTypes'
      "
      :data="allProductTypes"
      :search-key="searchKey"
      @click="onSelectItem"
      @sort="sortData"
    ></base-tree>
    <div v-else>
      <v-overlay>
        <v-progress-circular
          color="grey lighten-2"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import BaseTree from "./BaseTree.vue";

export default {
  components: {
    BaseTree
  },
  props: {
    searchKey: {
      type: null
    }
  },
  watch: {
    async searchKey() {
      await this.$store.dispatch(
        "PRODUCT_TYPE/getAllProductTypes",
        this.$props.searchKey
      );
    }
  },

  computed: {
    allProductTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    },
    productTypeStatusRequest() {
      return this.$store.getters["PRODUCT_TYPE/statusRequest"];
    }
  },
  methods: {
    async onSelectItem(selectItem) {
      await this.$store.dispatch(
        "PRODUCT_TYPE/getProductTypeById",
        selectItem.id
      );
      this.$emit("updateSelectedProductTypes", selectItem);
    },
    async sortData(val) {
      await this.$store.dispatch("PRODUCT_TYPE/sortProductTypes", val);
    }
  }
};
</script>

<route>
  {
    "name": "category",
    "meta": { "auth": true }
  }
  </route>
