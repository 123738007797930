<template>
  <div class="base-tree-item">
    <draggable
      v-model="dataFilter"
      @start="drag = true"
      @end="drag = false"
      @change="sortData"
    >
      <div
        v-for="element in dataFilter"
        :key="element.id"
        class="base-tree-item__node"
        @click="onSelect(element)"
        @mouseover="select(element.id)"
      >
        <div class="d-flex justify-space-between">
          <div>
            <div class="d-flex align-center">
              <div class="mr-2" v-show="element.id === hover">
                <svg
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16"
                  width="20"
                  height="20"
                >
                  <path
                    fill="currentColor"
                    d="M5.5 5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm0 4.5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm1.5 3a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0ZM10.5 5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3ZM12 8a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0Zm-1.5 6a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Z"
                  ></path>
                </svg>
              </div>
              <span
                color="primary"
                class="mr-2"
                :class="{ 'is-active': element.id === activatedId }"
                >{{ element.label }}</span
              >
              <v-btn class="cyan lighten-5 rounded-lg" color="primary" text>
                {{ element.count_children }}
              </v-btn>
            </div>
          </div>
          <v-icon size="18px" v-show="isShowIcon(element)"
            >mdi-chevron-right</v-icon
          >
        </div>
      </div>
    </draggable>
    <!-- <div
      v-for="(item, index) in items"
      :key="index"
      class="base-tree-item__node"
      @click="onSelect(item)"
    >
      <div class="d-flex justify-space-between">
        <div>
          <span
            color="primary"
            class="mr-2"
            :class="{ 'is-active': item.id === activatedId }"
            >{{ item.label }}</span
          >
          <v-btn class="cyan lighten-5 rounded-lg" color="primary" text>
            {{ item.count_children }}
          </v-btn>
        </div>
        <v-icon size="18px" v-show="isShowIcon(item)">mdi-chevron-right</v-icon>
      </div>
    </div> -->
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  props: {
    items: {
      type: Array
    }
  },
  data() {
    return {
      activatedId: null,
      data: [],
      dataFilter: [],
      hover: null
    };
  },
  watch: {
    items() {
      this.dataFilter = this.items;
    }
  },
  created() {
    this.dataFilter = this.items;
  },
  computed: {},
  methods: {
    isShowIcon(item) {
      const isActivatedItem =
        item.id === this.activatedId && item.count_children > 0;

      return isActivatedItem;
    },
    onSelect(item) {
      this.activatedId = item.id;
      this.$emit("select", item);
    },
    select(id) {
      this.hover = id;
    },

    sortData() {
      this.$emit("sort", this.dataFilter);
    }
  }
};
</script>
<style>
.base-tree-item {
  min-width: 350px;
  border-right: 1px solid gray;
  overflow-y: scroll;
}
.base-tree-item__node {
  cursor: pointer;
  padding: 8px 16px 8px 16px;
  width: 100%;
}
.base-tree-item__node:hover {
  background-color: rgb(241 245 249);
}
.is-active {
  color: #27bece;
}
</style>
