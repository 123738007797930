<template>
  <div>
    <v-row class="pl-5">
      <!-- Start: Main area -->
      <v-col cols="8" class="overflow-hidden py-0 d-flex flex-column">
        <!-- Start: Top actions -->
        <div class="d-flex justify-space-between">
          <top-action
            :search-key="searchKey"
            @updateSearchKey="updateSearchKey($event)"
          ></top-action>
          <!-- End: Top actions -->
          <!-- Start: Data table -->
        </div>
        <div class="mr-5 p-4 h-full bg-white category-container">
          <category :search-key="searchKey"></category>
        </div>
        <!-- End: Data table -->
      </v-col>
      <!-- Start: Filter area -->
      <form-product-type class="py-0"></form-product-type>
      <!-- End: Filter area -->
      <!-- End: Main area -->
    </v-row>
    <modal-category-detail></modal-category-detail>
  </div>
</template>

<script>
import FormProductType from "./components/FormProductType";
import Category from "./components/Category";
import TopAction from "./components/TopAction";
import ModalCategoryDetail from "./components/modal/ModalCategoryDetail";

export default {
  components: {
    FormProductType,
    TopAction,
    Category,
    ModalCategoryDetail
  },
  data() {
    return {
      selectedProductTypes: [],
      searchKey: null
    };
  },
  created() {
    this.getProductTypes();
    this.getTreeTypes();
  },
  methods: {
    // async pushRouter() {
    //   this.$router.push({
    //     name: "goods_product-types",
    //     query: {
    //       search:
    //         this.searchKey && this.searchKey.length > 0
    //           ? this.searchKey
    //           : undefined,
    //       limit: this.itemPerPage,
    //       page: this.currentPage
    //     }
    //   });
    // },

    async getProductTypes() {
      this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
    },
    async getTreeTypes() {
      this.$store.dispatch("PRODUCT_TYPE/getTreeProductTypes");
    },
    updateSearchKey(val) {
      this.searchKey = val.searchKey;
    }
  }

  // updateCurrentPage(page) {
  //   if (this.currentPage === page) return;
  //   this.currentPage = page;
  //   this.selectedProductTypes = [];
  //   // Push router query
  //   this.pushRouter();
  // },

  // async updateItemPerPage(num) {
  //   if (this.itemPerPage === num) return;
  //   this.itemPerPage = num;
  //   this.currentPage = 1;
  //   this.selectedProductTypes = [];
  //   // Push router query
  //   this.pushRouter();
  // },
};
</script>

<style lang="scss" scoped>
.category-container {
  overflow-x: scroll;
  height: calc(100vh - 64px - 53px - 40px - 44px);
}
</style>
