<template>
  <v-col v-if="productType.id" cols="4">
    <div class="font-weight-bold text-h5 mb-4">Loại sản phẩm</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <v-card-title class="font-weight-bold pa-0 mb-5">
        {{ productType.id ? "Cập nhật loại sản phẩm" : "Thêm loại sản phẩm" }}
      </v-card-title>

      <v-btn
        class="mb-2"
        color="primary"
        depressed
        outlined
        @click="goToTemplatePage"
      >
        <v-icon left>mdi-pencil</v-icon>
        Template cấu hình
      </v-btn>

      <v-form ref="productTypeForm" lazy-validation>
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Tên loại sản phẩm</div>
              <tp-text-field
                v-model="productType.name"
                placeholder="Nhập tên loại sản phẩm"
                validate="required"
              ></tp-text-field>
            </v-col>

            <v-col class="py-0 category-bar-show" cols="12">
              <v-checkbox
                v-model="productType.cate_bar_show"
                :label="`Hiển thị trên Navbar`"
                class="p-0"
              ></v-checkbox>
            </v-col>

            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Mô tả</div>
              <v-textarea
                v-model="productType.description"
                background-color="white"
                class="text-body-1"
                counter
                dense
                filled
                maxlength="320"
                outlined
                placeholder="Nhập mô tả"
                single-line
                validate="required"
              ></v-textarea>
            </v-col>

            <v-col class="py-0 mt-1" cols="12">
              <div class="d-flex align-center justify-space-between mb-1">
                <div class="font-weight-bold">Ảnh Thumbnail</div>
              </div>

              <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
                Kích thước ảnh yêu cầu
                <span class="font-weight-bold">là 1200 x 675 px</span>
              </div>

              <tp-input-image
                :acceptedFileTypes="['image/*']"
                :height="675"
                :maxFileSize="500 * 1024"
                :src.sync="productType.thumbnail"
                :width="1200"
                allowCompress
                allowFileDimensionValidation
                allowFileSize
                allowFileTypeValidation
                class="flex-grow-1"
                forceType="jpeg"
                required
              ></tp-input-image>
            </v-col>

            <v-col class="py-0" cols="12">
              <div class="d-flex align-center justify-space-between mb-1">
                <div class="font-weight-bold">Icon</div>
              </div>

              <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
                Kích thước ảnh yêu cầu là
                <span class="font-weight-bold">512 x 512 px</span>
              </div>

              <tp-input-image
                :acceptedFileTypes="['image/png']"
                :height="256 * 2"
                :maxFileSize="300 * 1024"
                :src.sync="productType.icon"
                :width="256 * 2"
                allowCompress
                allowFileDimensionValidation
                allowFileSize
                allowFileTypeValidation
                class="flex-grow-1"
                forceType="png"
                required
              ></tp-input-image>
            </v-col>

            <v-col class="py-0" cols="12">
              <form-site-model-available
                v-model="modelSiteConfigs"
                :model-id="productType.id"
                :model-type="LIST_MODEL_TYPES.PRODUCT_TYPE"
              />
            </v-col>

            <v-col class="py-0 mt-1 mb-4" cols="12">
              <div class="font-weight-bold mb-2">
                Loại sản phẩm cha
              </div>
              <treeselect
                v-model="productType.parent_id"
                :options="this.categoryTrees"
                class="custom-treeselect-category"
                height="100"
                placeholder="Chọn Category"
                search-nested
              ></treeselect>
            </v-col>

            <!-- <v-col v-if="productType.parent_id" class="py-0 mt-1" cols="12">
              <div class="font-weight-bold mb-2">Thứ tự hiển thị</div>
              <tp-select
                v-model="productType.priority"
                placeholder="Chọn thứ tự hiển thị"
                :items="priorityList"
                validate="required"
              ></tp-select>
            </v-col> -->

            <v-col class="py-0 mt-1 mb-4" cols="12">
              <form-seo-data
                :key="productType.id"
                v-model="productType.seo_data"
              ></form-seo-data>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions class="px-0 pb-0 d-flex justify-end">
        <v-btn
          class="grey lighten-5 rounded-lg mr-2"
          color="grey--text text--darken-1"
          text
          @click="resetProductType()"
        >
          Hủy
        </v-btn>
        <v-btn
          v-if="productType.id"
          :loading="
            productTypeStatusRequest.value === 'loading-createProductType'
          "
          class="mr-2"
          color="error"
          depressed
          @click="deleteProductType()"
        >
          Xóa
        </v-btn>
        <v-btn
          v-if="productType.id"
          :loading="
            productTypeStatusRequest.value === 'loading-createProductType'
          "
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="updateProductType()"
        >
          Lưu
        </v-btn>
        <v-btn
          v-else
          :loading="
            productTypeStatusRequest.value === 'loading-updateProductType'
          "
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="createProductType()"
        >
          Thêm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { toFormData } from "@vt7/utils";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import FormSeoData from "@/core/components/form/FormSeoData";
import FormSiteModelAvailable from "@/core/components/form/FormSiteModelAvailable";
import { LIST_MODEL_SITES, LIST_MODEL_TYPES } from "@/core/constant";

export default {
  components: { FormSeoData, Treeselect, FormSiteModelAvailable },
  data() {
    return {
      currentTab: "THINKPRO",
      LIST_MODEL_SITES,
      LIST_MODEL_TYPES,
      modelSiteConfigs: []
    };
  },
  computed: {
    priorityList() {
      if (!this.productTypeParent || !this.productTypeParent.count_children) {
        return [1];
      } else {
        const N = this.productType.id
          ? this.productTypeParent.count_children
          : this.productTypeParent.count_children + 1;

        return Array.from(Array(N), (_, index) => index + 1);
      }
    },
    productType() {
      return this.$store.getters["PRODUCT_TYPE/productType"];
    },
    categoryTrees() {
      return this.$store.getters["PRODUCT_TYPE/categoryTrees"];
    },
    productTypeParent() {
      return this.productTypes.find(
        item => item.id === this.productType.parent_id
      );
    },
    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    },
    productTypeStatusRequest() {
      return this.$store.getters["PRODUCT_TYPE/statusRequest"];
    },
    uploadedThumbnail() {
      return this.$store.getters["PRODUCT_TYPE/uploadedThumbnail"];
    },
    seoData() {
      return this.productType.seo_data.reduce(
        (obj, cur) => ({ ...obj, [cur.site]: cur }),
        {}
      );
    }
  },

  watch: {
    "productType.id": {
      async handler(val) {
        if (val) {
          this.getModelSiteConfigs();
        }
      },
      immediate: true
    }
  },

  methods: {
    goToTemplatePage() {
      this.$router.replace(
        `/goods/product-types/attribute-template/${this.productType.id}?name=${this.productType.name}`
      );
    },
    async onUploadThumbnailDesktop() {
      await this.uploadThumbnail(
        toFormData({
          file: this.productType.thumbnail
        })
      );

      // Set uploaded thumbnail
      if (this.productTypeStatusRequest.value === "success-uploadThumbnail") {
        this.productType.thumbnail = this.uploadedThumbnail;
      } else if (
        this.productTypeStatusRequest.value === "error-uploadThumbnail"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh bìa desktop",
          message: this.productTypeStatusRequest.message
        });

        return;
      }
    },

    async onUploadIcon() {
      await this.uploadThumbnail(
        toFormData({
          file: this.productType.icon
        })
      );

      // Set uploaded thumbnail
      if (this.productTypeStatusRequest.value === "success-uploadThumbnail") {
        this.productType.icon = this.uploadedThumbnail;
      } else if (
        this.productTypeStatusRequest.value === "error-uploadThumbnail"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh đại diện",
          message: this.productTypeStatusRequest.message
        });

        return;
      }
    },

    async getModelSiteConfigs() {
      this.modelSiteConfigs = await this.$store.dispatch(
        "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
        {
          modelId: this.productType.id,
          modelType: LIST_MODEL_TYPES.PRODUCT_TYPE
        }
      );
    },

    resetProductType() {
      this.$store.dispatch("PRODUCT_TYPE/resetProductType");
      this.$refs.productTypeForm.resetValidation();
      this.modelSiteConfigs = [];
    },

    updateProductSite() {
      this.productType.sites = [];
      this.modelSiteConfigs.map(item => {
        if (item.available) {
          this.productType.sites.push(item.site);
          return item.site;
        }
      });
    },

    async updateProductType() {
      const isValid = this.$refs.productTypeForm.validate();

      if (!isValid) return;

      // TODO: Write reused function
      // Check thumbnail changes
      if (this.productType.thumbnail instanceof File) {
        await this.onUploadThumbnailDesktop();
      }

      // Check cate bar thumbnail changes
      if (this.productType.icon instanceof File) {
        await this.onUploadIcon();
      }

      this.updateProductSite();

      const routeQuery = this.$route.query;

      this.productType.parent_id = this.productType.parent_id || null;

      await this.$store.dispatch("PRODUCT_TYPE/updateProductType", {
        data: this.productType,
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });

      // Alert
      if (this.productTypeStatusRequest.value === "success-updateProductType") {
        await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: this.modelSiteConfigs,
          modelId: this.productType.id,
          modelType: LIST_MODEL_TYPES.PRODUCT_TYPE
        });

        // get productTypes after change to display

        showToastActionAlert(this, { message: "Đã lưu thay đổi" });
      } else if (
        this.productTypeStatusRequest.value === "error-updateProductType"
      ) {
        showModalAlertError(this, {
          title: "Không thể cập nhật loại sản phẩm",
          message: this.productTypeStatusRequest.message
        });
      }
    },

    async deleteProductType() {
      // Confirm
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa loại sản phẩm <strong>"${this.productType.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch("PRODUCT_TYPE/deleteProductTypes", {
                data: {
                  ids: [this.productType.id]
                }
              });
              if (
                this.productTypeStatusRequest.value ===
                "success-deleteProductTypes"
              ) {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
                this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
              }
            }
          }
        }
      });
    },

    async uploadThumbnail(formData) {
      if (!formData) return;

      await this.$store.dispatch("PRODUCT_TYPE/uploadThumbnail", formData);
    }
  }
};
</script>

<style lang="scss">
.custom-treeselect-category .vue-treeselect__value-container {
  font-size: 14px;
}

.category-bar-show .v-input--selection-controls {
  margin: 0px;
}
</style>
